import { Vehicle } from '../../../../types/vehicle';
import NumberFormat from 'react-number-format';
import { useAdminOfferAuction, useCustomerHoverStateHandlers } from '../hooks';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { HoverInfoTooltip } from './HoverInfo';
import { useMemo, useRef } from 'react';
import { useIsTextOverflown } from '../../../../hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  font-weight: 500;
`;

const Label = styled.span`
  color: #757575;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  max-width: 66px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 8px;
`;

type Props = {
  vehicle: Vehicle;
};

const companyToLabel: Record<string, string> = {
  acv: 'ACV',
  other: 'Other',
  open_lane: 'OL'
};

export const AdminProxyOffers = ({ vehicle }: Props) => {
  const offerAuction = useAdminOfferAuction(vehicle.id);
  const proxyOfferBid = offerAuction?.offerProxyBid;
  const admin = useSelector((state: any) => state?.user?.user);

  console.log(proxyOfferBid, 'proxyOfferBid');

  const { isOpen, handleClose, handleOpen } = useCustomerHoverStateHandlers();

  const ref = useRef<HTMLSpanElement>(null);
  const isTextOverflown = useIsTextOverflown(ref);

  const label = useMemo(() => {
    if (proxyOfferBid?.bidder.is_bot) {
      return `${companyToLabel[proxyOfferBid.bidder.company_name]} - ${proxyOfferBid.bidder.dealership_name}`;
    }

    return proxyOfferBid?.bidder.dealership_name || '';
  }, [proxyOfferBid]);

  return (
    <Wrapper>
      <NumberFormat displayType="text" prefix="$" value={proxyOfferBid?.amount ?? 0} thousandSeparator />
      {proxyOfferBid?.amount && (
        <HoverInfoTooltip
          anchorEl={ref.current}
          initialPage={`/admin/auctions/${vehicle.auction_id}`}
          isPopoverOpened={isOpen}
          closeTooltip={handleClose}
          showTooltip={isTextOverflown}
          userId={proxyOfferBid.bidder.id}
          tooltipName={proxyOfferBid.bidder.dealership_name}
        >
          <Label ref={ref} onClick={proxyOfferBid.bidder.is_bot ? undefined : handleOpen}>
            {label}
          </Label>
        </HoverInfoTooltip>
      )}
    </Wrapper>
  );
};
