import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import compact from 'lodash.compact';
import { formValueSelector, change, reset } from 'redux-form';
import { AppBar, Toolbar, Typography, Grid, Button, FormControl, Collapse } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import moment from 'moment';
import PageWrapper from '../../../components/PageWrapper';
import PictureUploadComponent from '../../../components/VehicleCreation/VehiclePictureUploadForm/components/PictureUploadComponent';
import LoaderModal from '../../../components/LoaderModal';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import {
  getVinInfo,
  modalsToggle,
  getCarEstimate,
  addVehicle,
  snackShow,
  vehiclesUnsetSuccess,
  getCurrentAuction,
  createVehicleSetTmpImage,
  vehicleDetails,
  resetVinInfo,
  updateVehicle,
  resetVehicleDetails,
  resetCarEstimate,
  setVehicleImages,
  listUsers
} from '../../../actions';
import { deleteVehicle } from '../../../actions/vehicles';
import FormField from './FormField';
import FormError from '../../../components/Form/FormError';
import VinForm from './VinForm';
import VehicleForm from './VehicleForm';
import QualityForm from './QualityForm';
import DisclaimersFrom from './DisclaimersForm';
import SellersForm from './SellersForm';
import { Auth } from '../../../utils';
import KBB from '../../../img/kbb_logo.png';
import { useGetCarEstimateMutation } from '../../../services/api';
import { roles, VEHICLE_STATUSES, DATE_FORMAT } from '../../../constants';

import './index.scss';
import { getForm, removeForm, setForm } from '../../../utils/vehicleForm';
import { imageLabels } from '../../../constants/images';

const KBBIcon = styled.img`
  height: 62.5px;
  width: 106.5px;
  // TODO: Remove position absolute
  position: absolute;
  top: 5px;
  right: 10px;
`;

const styles = {
  collapse: {
    padding: '2px'
  },
  imagesHolderMargin: {
    margin: '16px 0'
  },
  confirmSubmitButton: {
    marginLeft: 6,
    marginRight: '0 !important'
  },
  innerPadding: {
    margin: '8px 0'
  },
  submitRow: {
    '@media (max-width: 600px)': {
      marginTop: '10px'
    }
  }
};

const TypographyBreadcrumbs = styled(Typography)`
  && {
    font-size: 16px;
  }
`;

const StyledGridBottomButtonsHolder = styled(Grid)`
  > button {
    height: max-content;
  }
  @media only screen and (max-width: 600px) {
    justify-content: space-between;
  }
`;

let tmpStatus = 'draft';

export const VehicleAdd = props => {
  const [createdId, setCreatedId] = React.useState(null);
  const [mutateGetCarEstimate, { isUninitialized, isSuccess: carEstimateSuccess }] = useGetCarEstimateMutation();

  useEffect(() => {
    const { fetchCurrentAuction, initialValues, fetchUsers, setImages } = props;

    const handle = async () => {
      if (initialValues && initialValues.vin) {
        if (initialValues.vin) {
          await getVinInfo({
            vin: initialValues.vin,
            vehicleId: '0'
          });
        }

        await fetchUsers();

        if (initialValues.mileage) {
          await getCarEstimate({ mileage: initialValues.mileage.toString() });
        }

        if (initialValues.images) {
          setImages(initialValues.images);
        }
      }
      fetchCurrentAuction();
    };
    handle();
  }, []);

  useEffect(() => {
    console.log('add listener');
    window.addEventListener('beforeunload', unload);

    return () => {
      console.log('remove listener');
      window.removeEventListener('beforeunload', unload);
    };
  }, []);

  useEffect(() => {
    if (props.created) {
      setCreatedId(props.created);

      const { history, showSnack, unsetSuccess } = props;
      if (tmpStatus === 'draft') {
        showSnack({ message: 'Vehicle saved.' });
      } else {
        showSnack({ message: 'Vehicle uploaded.' });
      }
      unsetSuccess();
      if (tmpStatus === 'active') {
        setTimeout(() => {
          if (Auth.getRole() === roles.ADMIN) {
            history.push('/admin/vehicles');
          } else {
            history.push('/seller/vehicles');
          }
        }, 3000);

        // return () => clearTimeout(redirectTimeout); // Clear the timeout if the component unmounts
      }
    }
  }, [props]);

  useEffect(() => {
    const { loading, usersLoading, initialValues } = props;
    // const isLoading = loading || usersLoading;
    if (isUninitialized && initialValues?.mileage) {
      getCarEstimate({ mileage: initialValues.mileage.toString() });
    }
  }, [isUninitialized, props.initialValues?.mileage]);

  useEffect(() => {
    return () => {
      const { resetForm, resetDataVinInfo, resetVehicle, resetEstimate, setImages } = props;
      resetDataVinInfo();
      resetVehicle();
      resetEstimate();
      setImages([]);
      resetForm('sellersForm');
      resetForm('vinForm');
      resetForm('addVehicleForm');
      resetForm('qualityForm');
      resetForm('disclaimersForm');
    };
  }, []);

  const getVinInfo = async formValues => {
    const { fetchVinInfo } = props;
    // await fetchVinInfo(formValues.vin);
    if (!formValues.vehicleId) {
      await fetchVinInfo({ ...formValues, vehicleId: 0, createSage: true });
    } else {
      await fetchVinInfo({ ...formValues, createSage: true });
    }
  };

  const isSellerSelected = (() => {
    const { sellersForm } = props;
    if (Auth.getRole() === roles.SELLER) return true;
    return Boolean(
      Auth.getRole() === roles.ADMIN &&
        sellersForm &&
        sellersForm.values &&
        (typeof sellersForm.values.seller === 'number' || sellersForm.values.seller?.value)
    );
  })();

  const getCarEstimate = async formValues => {
    if (!isSellerSelected) return;
    const { additionalOptions, currentVinInfo, currentOptions, sellersForm, users, currentUser, initialValues } = props;

    const selectedOptions = currentOptions
      ? currentOptions.filter(item => item.isTypical).map(item => item.vehicleOptionId)
      : [];

    const selectedEngine = currentVinInfo.engines.find(option => option.optionName === additionalOptions.engine);
    const selectedTransmission = currentVinInfo.transmissions.find(
      option => option.optionName === additionalOptions.transmission
    );
    const selectedDriveTrain = currentVinInfo.drive_trains.find(
      option => option.optionName === additionalOptions.drive_train
    );

    if (selectedEngine && selectedTransmission && selectedDriveTrain) {
      selectedOptions.push(
        selectedEngine.vehicleOptionId,
        selectedDriveTrain.vehicleOptionId,
        selectedTransmission.vehicleOptionId
      );
    }

    let zip = (initialValues && initialValues.seller && initialValues.seller.zip) || '0';
    if (Auth.getRole() === roles.ADMIN && sellersForm && sellersForm.values && sellersForm.values.seller) {
      if (sellersForm.values.seller && sellersForm.values.seller.value) {
        zip = users.find(item => item.id === sellersForm.values.seller.value).zip || 0;
      } else if (users && users.length) {
        zip = users.find(item => item.id === sellersForm.values.seller).zip || 0;
      }
    } else if (Auth.getRole() !== roles.ADMIN) {
      // eslint-disable-next-line
      zip = currentUser.user.zip;
    }

    await mutateGetCarEstimate({
      options: selectedOptions,
      bluebook_vehicle_id: currentVinInfo.vehicleId,
      mileage: formValues.mileage.toString().replace(/[\D]/g, ''),
      zip
    });
  };

  const handleSubmitVehicle = async status => {
    const {
      createVehicle,
      currentVin,
      addVehicleForm,
      qualityForm,
      vehicleImages,
      sellersForm,
      editVehicle,
      selectedVehicle,
      disclaimersForm,
      showSnack,
      requestInProgress
    } = props;

    const isImageLoading = vehicleImages.some(image => image.loading);
    const imageUploadIsFailed = vehicleImages.some(image => image.failed);

    if (requestInProgress) {
      return false;
    }
    if (imageUploadIsFailed) {
      showSnack({
        message: 'One of the images failed to upload. Try to upload them again or delete failed images',
        type: 'error'
      });
      return false;
    }

    if (isImageLoading) {
      showSnack({
        message: 'Images is still loading, please wait this image to be uploaded',
        type: 'error'
      });
      return false;
    }

    const errors = !!addVehicleForm.syncErrors || !!qualityForm.syncErrors || !!disclaimersForm.syncErrors;
    if (errors) {
      showSnack({
        message: 'Validation failed',
        type: 'error'
      });
      return false;
    }

    console.log('remove listener');
    window.removeEventListener('beforeunload', unload);

    const options = [];
    if (addVehicleForm && addVehicleForm.values.options) {
      addVehicleForm.values.options
        .filter(option => typeof option.isTypical === 'boolean')
        .forEach(({ optionName, secondaryName, vehicleOptionId, isTypical }) => {
          const label = secondaryName ? `${optionName} - ${secondaryName}` : optionName;
          options.push({
            vehicleOptionId,
            optionName: label,
            isTypical
          });
        });
    }
    const data = {
      vin: currentVin,
      auction_type: props.vinForm.auction_type,
      make: addVehicleForm.values.make || '',
      year: addVehicleForm.values.year || '',
      model: addVehicleForm.values.model || '',
      ext_color: addVehicleForm.values.ext_color || '',
      trim: addVehicleForm.values.trim || '',
      engine: addVehicleForm.values.engine || '',
      drive_train: addVehicleForm.values.drive_train || '',
      transmission: addVehicleForm.values.transmission || '',
      starting_bid: addVehicleForm.values.starting_bid || '',
      reserve_price: addVehicleForm.values.reserve_price || '',
      options,
      mileage: (addVehicleForm.values.mileage || '0').toString().replace(/[\D]/g, ''),
      bluebook_mileage: (addVehicleForm.values.bluebookMileage || '0').toString().replace(/[\D]/g, ''),
      bluebook_value: (addVehicleForm.values.bluebookValue || '0').toString().replace(/[\D]/g, ''),
      bluebook_mileage_adjustment: addVehicleForm.values.bluebook_mileage_adjustment || 0,
      bluebook_auction_value_good: addVehicleForm.values.bluebook_auction_value_good || 0,
      windshield: (qualityForm && qualityForm.values && Math.round(qualityForm.values.windshield)) || 75,
      tiers: (qualityForm && qualityForm.values && Math.round(qualityForm.values.tiers)) || 0,
      paint: (qualityForm && qualityForm.values && Math.round(qualityForm.values.paint)) || 0,
      air_conditioning_blows:
        (qualityForm && qualityForm.values && Math.round(qualityForm.values.air_conditioning_blows)) || 0,
      engine_oil: (qualityForm && qualityForm.values && Math.round(qualityForm.values.engine_oil)) || 0,
      engine_coolant: (qualityForm && qualityForm.values && Math.round(qualityForm.values.engine_coolant)) || 0,
      interior: (qualityForm && qualityForm.values && Math.round(qualityForm.values.interior)) || 0,
      check_engine_light: (qualityForm && qualityForm.values && qualityForm.values.engine_light) || false,
      abs_light: (qualityForm && qualityForm.values && qualityForm.values.abs_light) || false,
      airbag_light: (qualityForm && qualityForm.values && qualityForm.values.airbag_light) || false,
      dealer_comments: (qualityForm && qualityForm.values && qualityForm.values.dealerComments) || '',
      is_guaranteed: disclaimersForm && disclaimersForm.values && disclaimersForm.values.disclamer === 'guaranteed',
      status,
      images: vehicleImages.map(image => image.id)
    };

    if (sellersForm && sellersForm.values) {
      if (sellersForm.values.seller && sellersForm.values.seller.value) {
        data.seller_id = sellersForm.values.seller.value;
      } else {
        data.seller_id = sellersForm.values.seller;
      }
    }

    tmpStatus = status;

    if ((selectedVehicle && selectedVehicle.id) || createdId) {
      data.id = selectedVehicle.id || createdId;
      data.previous_status = selectedVehicle.status || tmpStatus;
      editVehicle(data);
    } else {
      createVehicle(data);
    }
    unload();

    return true;
  };

  const unload = () => {
    const {
      currentVin,
      addVehicleForm,
      qualityForm,
      vehicleImages,
      sellersForm,
      disclaimersForm,
      showSnack,
      requestInProgress,
      users
    } = props;

    const isImageLoading = vehicleImages.some(image => image.loading);
    const imageUploadIsFailed = vehicleImages.some(image => image.failed);

    if (requestInProgress || !currentVin) {
      return false;
    }
    if (imageUploadIsFailed) {
      showSnack({
        message: 'One of the images failed to upload. Try to upload them again or delete failed images',
        type: 'error'
      });
    }

    if (isImageLoading) {
      showSnack({
        message: 'Images is still loading, please wait this image to be uploaded',
        type: 'error'
      });
    }

    const options = [];
    if (addVehicleForm && addVehicleForm.values && addVehicleForm.values.options) {
      addVehicleForm.values.options
        .filter(option => typeof option.isTypical === 'boolean')
        .forEach(({ optionName, secondaryName, vehicleOptionId, isTypical }) => {
          const label = secondaryName ? `${optionName} - ${secondaryName}` : optionName;
          options.push({
            vehicleOptionId,
            optionName: label,
            isTypical
          });
        });
    }
    let data = {
      vin: currentVin,
      windshield: (qualityForm && Math.round(qualityForm.values.windshield)) || 75,
      tiers: (qualityForm && Math.round(qualityForm.values.tiers)) || 0,
      paint: (qualityForm && Math.round(qualityForm.values.paint)) || 0,
      air_conditioning_blows: (qualityForm && Math.round(qualityForm.values.air_conditioning_blows)) || 0,
      engine_oil: (qualityForm && Math.round(qualityForm.values.engine_oil)) || 0,
      engine_coolant: (qualityForm && Math.round(qualityForm.values.engine_coolant)) || 0,
      interior: (qualityForm && Math.round(qualityForm.values.interior)) || 0,
      check_engine_light: (qualityForm && qualityForm.values.engine_light) || false,
      abs_light: (qualityForm && qualityForm.values.abs_light) || false,
      airbag_light: (qualityForm && qualityForm.values.airbag_light) || false,
      dealer_comments: (qualityForm && qualityForm.values.dealerComments) || '',
      is_guaranteed: disclaimersForm && disclaimersForm.values.disclamer === 'guaranteed',
      images: compact(
        vehicleImages.map(el => {
          if (!el.loading) {
            // eslint-disable-next-line no-param-reassign
            el.imageUrl = el.url;
            return el;
          }

          return null;
        })
      )
    };

    if (addVehicleForm && addVehicleForm.values) {
      data = {
        ...data,
        make: addVehicleForm.values.make || '',
        year: addVehicleForm.values.year || '',
        model: addVehicleForm.values.model || '',
        ext_color: addVehicleForm.values.ext_color || '',
        trim: addVehicleForm.values.trim || '',
        engine: addVehicleForm.values.engine || '',
        drive_train: addVehicleForm.values.drive_train || '',
        transmission: addVehicleForm.values.transmission || '',
        starting_bid: addVehicleForm.values.starting_bid || '',
        reserve_price: addVehicleForm.values.reserve_price || '',
        options,
        mileage: (addVehicleForm.values.mileage || '0').toString().replace(/[\D]/g, ''),
        bluebook_mileage: (addVehicleForm.values.bluebookMileage || '0').toString().replace(/[\D]/g, ''),
        bluebook_value: (addVehicleForm.values.bluebookValue || '0').toString().replace(/[\D]/g, ''),
        bluebook_mileage_adjustment: addVehicleForm.values.bluebook_mileage_adjustment || 0,
        bluebook_auction_value_good: addVehicleForm.values.bluebook_auction_value_good || 0
      };
    }

    if (sellersForm && sellersForm.values) {
      if (sellersForm.values.seller && sellersForm.values.seller.value) {
        data.seller_id = sellersForm.values.seller.value;
      } else {
        data.seller_id = sellersForm.values.seller;
      }
    }

    if (data.seller_id) {
      data.seller = users.find(el => el.id === data.seller_id);
    }
    setForm(data);

    console.log('remove listener');
    window.removeEventListener('beforeunload', unload);

    return true;
  };

  const handleCancelVehicle = () => {
    const { history, selectedVehicle } = props;
    // eslint-disable-next-line
    if (confirm('Are you sure want to cancel vehicle creation?')) {
      console.log('remove listener');
      window.removeEventListener('beforeunload', unload);
      removeForm();
      if (Auth.getRole() === roles.ADMIN) {
        if (selectedVehicle && selectedVehicle.id && selectedVehicle.auction_id) {
          history.push(`/admin/auctions/${selectedVehicle.auction_id}`);
        } else {
          history.push('/admin/vehicles');
        }
      } else {
        history.push('/seller/vehicles');
      }
    }
  };

  const {
    vehicles,
    classes,
    createError,
    carEstimateError,
    vehicleImages,
    loading,
    currentAutoaxessAuction,
    currentFlAuction,
    auctionType,
    sellersForm,
    currentVin,
    selectedVehicle,
    edit: isEdit,
    currentUser,
    removeVehicle,
    confirmVehicleDeleting,
    requestInProgress,
    initialValues
  } = props;
  const isAdmin = currentUser.user.role === roles.ADMIN;
  const imagesCount = vehicleImages.length;
  const isActive =
    Object.keys(selectedVehicle).length > 0 &&
    (selectedVehicle.id || isEdit) &&
    selectedVehicle.status !== VEHICLE_STATUSES.DRAFT;
  // eslint-disable-next-line no-nested-ternary
  const imgs =
    vehicleImages && vehicleImages.length
      ? vehicleImages
      : initialValues && initialValues.images
      ? initialValues.images
      : [];
  const images = Array(25)
    .fill()
    .reduce((acc, item, index) => {
      const indexExists = imgs.find(image => image.key === index);
      console.log(indexExists, 'indexExists');
      if (indexExists) return [...acc, indexExists];
      return [...acc, index];
    }, []);

  console.log(auctionType, 'auctionType');
  const currentAuction = auctionType === 'autoaxess' ? currentAutoaxessAuction : currentFlAuction;

  return (
    <div className="vehicle-add">
      <AppBar position="fixed" color="default">
        <PageWrapper>
          <Toolbar disableGutters>
            <TypographyBreadcrumbs variant="title" color="inherit" style={{ flexGrow: 1 }}>
              Upload Vehicle
            </TypographyBreadcrumbs>
            <LoaderModal modalId="loaderModal" loaderText="Saving Information" />
            <div className="actions">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancelVehicle}
                disabled={loading || requestInProgress}
              >
                <i className="material-icons">close</i>
                <span>Cancel</span>
              </Button>
              <Button
                variant="contained"
                color={imagesCount === 0 ? 'primary' : 'secondary'}
                disabled={!vehicles.vinInfo.success || loading || requestInProgress}
                onClick={() => handleSubmitVehicle(isActive ? 'active' : 'draft')}
              >
                <i className="material-icons">save</i>
                <span>{isActive ? 'Update' : 'Save As Draft'}</span>
              </Button>
              {!isActive && currentAuction ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmitVehicle('active')}
                  disabled={loading || imagesCount === 0 || requestInProgress}
                >
                  <i className="material-icons">send</i>
                  <span>Submit</span>
                </Button>
              ) : null}
            </div>
          </Toolbar>
        </PageWrapper>
      </AppBar>
      <PageWrapper>
        <Grid container spacing={16} className="form">
          {Auth.getRole() === roles.ADMIN && (
            <Grid item xs={12}>
              <Collapse in classes={{ container: 'select-container', wrapper: classes.collapse }}>
                <FormField title="Seller">
                  <SellersForm
                    initialValues={
                      initialValues && {
                        seller: initialValues.seller_id
                      }
                    }
                    sellerId={selectedVehicle.user_id || 0}
                  />
                </FormField>
              </Collapse>
            </Grid>
          )}
          <Grid item xs={12}>
            <Collapse
              in={Auth.getRole() !== roles.ADMIN || (sellersForm && sellersForm.values)}
              classes={{ wrapper: classes.collapse }}
            >
              <FormField title="VIN Number">
                <VinForm
                  initialValues={
                    initialValues
                      ? {
                          vin: initialValues.vin,
                          auction_type: 'autoaxess'
                        }
                      : {
                          auction_type: 'autoaxess'
                        }
                  }
                  isAdmin={Auth.getRole() === roles.ADMIN}
                  getVinInfo={getVinInfo}
                  vehicleId={selectedVehicle.id || 0}
                />
              </FormField>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={vehicles.vinInfo.success} classes={{ wrapper: classes.collapse }}>
              <FormField title="Vehicle Information">
                <KBBIcon alt="provided_by_kbb" src={KBB} />
                {vehicles.vinInfo.success ? (
                  <VehicleForm
                    isSellerSelected={isSellerSelected}
                    initialValues={initialValues}
                    getCarEstimate={getCarEstimate}
                    carEstimateSuccess={carEstimateSuccess}
                  />
                ) : null}
                {carEstimateError && (
                  <Grid item xs={12}>
                    <FormError msg={carEstimateError} />
                  </Grid>
                )}
              </FormField>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            {/* in={carEstimateSuccess} */}
            <Collapse in={carEstimateSuccess} classes={{ wrapper: classes.collapse }}>
              <FormField title="More Vehicle Information">
                <QualityForm initialValues={initialValues} vin={currentVin} />
              </FormField>
              <FormField title="Vehicle Pictures Uploader" style={{ margin: '16px 0' }}>
                <Grid container item spacing={16} id="pictures">
                  <Grid item xs={12}>
                    <Typography variant="subheading" gutterBottom>
                      Load at least one photo of the vehicle (front passenger). Load up to 25
                    </Typography>
                  </Grid>
                  {images.map((image, i) => (
                    <Grid key={i} item lg={3} md={4} sm={6} xs={12}>
                      <PictureUploadComponent label={imageLabels[i] || ''} itemKey={i} image={image} />
                    </Grid>
                  ))}
                  {/* {images.length < 25 && (
                    <Grid item lg={3} md={4} sm={6} xs={12} className="picture">
                      <PictureUploadComponent label="Additional equipment or damage" itemKey={images.length + 1} />
                    </Grid>
                  )} */}
                </Grid>
                {images.length === 0 && (
                  <Grid item xs={12}>
                    <FormError msg="Load at least one photo" />
                  </Grid>
                )}
              </FormField>
              <FormField title="Disclaimer">
                <FormControl>
                  <DisclaimersFrom
                    initialValues={{
                      disclamer: 'responsible'
                    }}
                  />
                </FormControl>
              </FormField>
            </Collapse>
          </Grid>
          {createError && (
            <Grid item xs={12}>
              <FormError msg={createError} />
            </Grid>
          )}
          {carEstimateSuccess && (
            <Grid item container xs={12} justify="space-between" direction="row" id="confirmation">
              <StyledGridBottomButtonsHolder item container xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelVehicle}
                  disabled={loading || requestInProgress}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSubmitVehicle(isActive ? 'active' : 'draft')}
                  disabled={loading || requestInProgress}
                >
                  {isActive ? 'Update' : 'Save As Draft'}
                </Button>
                {!isActive && currentAuction && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={[classes.confirmSubmitButton, 'mobile-show']}
                    onClick={() => handleSubmitVehicle('active')}
                    disabled={loading || imagesCount === 0 || requestInProgress}
                  >
                    <span>Submit</span>
                  </Button>
                )}
              </StyledGridBottomButtonsHolder>
              <Grid item xs={12} sm={8} container justify="flex-end" alignItems="center" className={classes.submitRow}>
                {!isActive && currentAuction && (
                  <>
                    <Grid item xs={8}>
                      <Typography>
                        Upon clicking submit, you agree to listing this vehicle in auction ending,{' '}
                        {moment(currentAuction.date_end).format(DATE_FORMAT)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className="text-right">
                      <Button
                        variant="contained"
                        color="primary"
                        className={[classes.confirmSubmitButton, 'mobile-hide']}
                        onClick={() => handleSubmitVehicle('active')}
                        disabled={loading || imagesCount === 0 || requestInProgress}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </>
                )}
                {!currentAuction && !isActive && (
                  <Grid item xs={12} className="text-right">
                    <Typography>
                      Currently, there is not an active auction to list the vehicle in. Please save as a draft until
                      AutoAxess creates a new auction.
                    </Typography>
                  </Grid>
                )}
                {isEdit && isAdmin && (
                  <Grid item xs={12} className="text-right">
                    {/* Temporary solution to not break current UI */}
                    <button type="submit" className="hidden" />
                    <ConfirmModal
                      modalId="deleteVehicleModal"
                      title="Are you sure you want to delete this vehicle?"
                      handleSubmit={() => removeVehicle(selectedVehicle.id)}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={confirmVehicleDeleting}
                      disabled={loading || requestInProgress}
                    >
                      <span>Delete</span>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </PageWrapper>
    </div>
  );
};

const mapStateToProps = state => {
  const formValues = getForm() || {};
  const sellerId = (() => {
    if (formValues.sellerId) return formValues.sellerId;
    if (!state.sellerLock.is_seller_locked) return null;
    return Number(localStorage.getItem('locked_seller')) ?? null;
  })();
  return {
    vehicles: state.vehicles,
    addVehicleForm: state.form.addVehicleForm,
    qualityForm: state.form.qualityForm,
    sellersForm: state.form.sellersForm,
    disclaimersForm: state.form.disclaimersForm,
    vinForm: state.form.vinForm?.values,
    currentVin: formValueSelector('vinForm')(state, 'vin'),
    currentOptions: formValueSelector('addVehicleForm')(state, 'options'),
    createError: state.vehicles.error,
    vinError: state.vehicles.vinInfoError,
    carEstimateError: state.vehicles.carEstimateError,
    created: state.vehicles.success,
    vehicleImages: state.vehicles.vehicleImages,
    loading: state.vehicles.loading,
    requestInProgress: state.vehicles.requestInProgress,
    currentFlAuction: state.auctions.currentFrontline,
    auctionType: formValueSelector('vinForm')(state, 'auction_type'),
    currentAutoaxessAuction: state.auctions.current,
    selectedVehicle: state.vehicles.selectedVehicle.data || {},
    users: state.users.items || [],
    currentUser: state.user,
    currentVinInfo: state.vehicles.vinInfo.data || {},
    initialValues: {
      ...formValues,
      seller_id: sellerId,
      trim:
        state.vehicles.selectedVehicle && state.vehicles.selectedVehicle.data
          ? state.vehicles.selectedVehicle.data.trim
          : ''
    },
    additionalOptions: formValueSelector('addVehicleForm')(state, 'engine', 'transmission', 'drive_train')
  };
};

const mapDispatchToProps = dispatch => ({
  fetchVinInfo: data => new Promise((resolve, reject) => dispatch(getVinInfo(data, resolve, reject).request)),
  fetchCarEstimate: data => new Promise((resolve, reject) => dispatch(getCarEstimate(data, resolve, reject).request)),
  fetchUsers: params => dispatch(listUsers(params).request),
  createVehicle: data => dispatch(addVehicle(data).request),
  editVehicle: data => dispatch(updateVehicle(data).request),
  showSnack: message => dispatch(snackShow(message)),
  unsetSuccess: () => dispatch(vehiclesUnsetSuccess()),
  fetchCurrentAuction: () => dispatch(getCurrentAuction().request),
  fetchDetails: vehicleId =>
    new Promise((resolve, reject) => dispatch(vehicleDetails({ vehicleId }, resolve, reject).request)),
  setFormField: (form, field, value) => dispatch(change(form, field, value)),
  removeVehicle: payload => dispatch(deleteVehicle(payload).request),
  resetForm: form => dispatch(reset(form)),
  resetDataVinInfo: () => dispatch(resetVinInfo()),
  resetVehicle: () => dispatch(resetVehicleDetails()),
  resetEstimate: () => dispatch(resetCarEstimate()),
  setImages: payload => dispatch(setVehicleImages(payload)),
  updateImg: data => dispatch(createVehicleSetTmpImage(data)),
  confirmVehicleDeleting: () => dispatch(modalsToggle('deleteVehicleModal'))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VehicleAdd));
