export const imageLabels = [
  'Front/Passenger side 3/4 view',
  'Front',
  "Driver's side",
  'Back (including lic plate)',
  'Passenger side',
  'Under hood',
  'Front seats',
  'Back seats',
  'Full dash',
  'Instrument cluster',
  'VIN plate',
  'Engine codes',
  'Smog readiness',
  'Additional Image 1',
  'Additional Image 2',
  'Additional Image 3',
  'Additional Image 4',
  'Additional Image 5',
  'Additional Image 6',
  'Additional Image 7',
  'Additional Image 8',
  'Additional Image 9',
  'Additional Image 10',
  'Additional Image 11',
  'Additional Image 12',
  'Additional Image 13',
  'Additional Image 14',
  'Additional Image 15',
  'Additional Image 16'
];
