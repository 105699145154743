import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from '../../../types/vehicle';
import apiSlice from '..';
import { EntityWithCount, SocketMessage } from '../types';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import { User } from '../../../types/user';
import getSocket from '../../socket';
import { modalsHide, snackShow } from '../../../actions';
import { resetWithoutType } from '../../auctions/auctionsListSlice';

type AuctionVehiclesQueryArgs = {
  [x: string]: any;
};

export enum OfferLogOperationType {
  BuyerToSeller = 'buyer_to_seller',
  SellerToBuyer = 'seller_to_buyer',
  AdminToBuyer = 'admin_to_buyer',
  TopBuyerAccepted = 'top_buyer_accepted',
  SellerAcceptedFromTopBuyer = 'seller_accepted_from_top_buyer',
  AdminAcceptedFromTopBuyer = 'admin_accepted_from_top_buyer',
  BuyerPlacedOffer = 'buyer_placed_offer',
  AdminToBuyers = 'admin_to_buyers',
  SellerToAdmin = 'seller_to_admin',
  SellerAccepted = 'seller_accepted',
  AdminApproved = 'admin_approved',
  BuyerAccepted = 'buyer_accepted',
  AdminPulled = 'admin_pulled',
  AdminStoppedCounter = 'admin_stopped_counter',
  AdminAwarded = 'admin_awarded',
  SoldWithReserve = 'sold_with_reserve',
  UnawardVehicle = 'unaward_vehicle',
  RelistVehicle = 'relist_vehicle',
  RelistVehicleInto = 'relist_vehicle_into',
  AdminEditedOffer = 'admin_edited_offer',
  AdminExtended = 'admin_extended',
  AdminPlacedBid = 'admin_placed_bid',
  AdminPlacedFakeBid = 'admin_placed_fake_bid',

  VehicleListed = 'vehicle_listed',
  BuyerPlacedBid = 'buyer_placed_bid',
  BuyerPlacedBidAndReserveMet = 'buyer_placed_bit_and_reserve_met',
  AdminDeletedBid = 'admin_deleted_bid',
  AdminModifiedBid = 'admin_modified_bid',
  AdminDeletedProxy = 'admin_deleted_proxy',
  AdminModifiedProxy = 'admin_modified_proxy',
  AdminDeletedAllBids = 'admin_deleted_all_bids',

  AdminLockedOrder = 'admin_locked_order',
  AdminUnlockedOrder = 'admin_unlocked_order'
}

export enum OfferType {
  Regular = 'regular',
  AdminPlacedOfferForBuyer = 'admin_placed_offer_for_buyer',
  AdminPlacedOfferForTopBuyer = 'admin_placed_offer_for_top_buyer'
}

export type OfferLogData = {
  id: number;
  author: User;
  vehicle_id: number;
  vehicle: Vehicle;
  auction_id: number;
  created_at: string;
  offer_amount_type?: 'upgrade' | 'downgrade';
  operation_type: OfferLogOperationType;
  amount?: number;
  buyer_id?: number;
  buyer?: User;
  seller_id: number;
  seller: User;
  order_index: number;
  is_losing_bid?: boolean;
  proxy_amount?: number;
  previous_amount?: number;
  offer_type?: OfferType;
  relisted_from_auction_id?: number;
  relisted_into_auction_id?: number;
};

export type VehicleRelistData = {
  id: number;
  title: string;
  activeAuctions: Array<{ id: number; startDate: string; slug: string }>;
  maxOffer?: number;
  proxyOffer?: number;
  listOfBuyers: string[];
  initialReserve?: number;
  finalReserve?: number;
  blueBookValue: number;
  latestBlueBookValue?: number;
  notes?: string;
  aaCounter?: number;
  seller?: string;
  startingBid: number;
};

type CreateRelistPayload = {
  auctionId: number;
  vehicleId: number;
  maxOffer: number;
  maxProxy: number;
  newReserve: number;
  initialReserve: number;
  finalReserve: number;
  aaCounter: number;
  minSellerWillTake: number;
  difference: number;
  notes: string;
};

export type RelistHistoryRecord = {
  id: number;
  vehicle_id: number;
  relisted_from_id: number;
  relisted_from_order_index: number;
  relisted_at: string;
  max_offer: number;
  max_proxy: number;
  new_reserve: number | null;
  initial_reserve: number;
  final_reserve: number;
  aa_counter: number | null;
  seller: string;
  buyer: string;
  min_seller_take: number | null;
  bluebook_value: number | null;
  difference: number;
  notes: string;
  created_at: string;
  updated_at: string;
  vehicle: Vehicle;
  starting_bid: number;
  auction: any;
};

type MinBuyerOffer =
  | { offerAllowed: true; amount: number; offerType: 'bid' | 'offer' }
  | { offerAllowed: false; disallowedReason: string; offerType: 'bid' | 'offer' };

// TODO: better typing with ADMIN_AUCTION_STATUS
export type AuctionStatusFilter = Record<string, number>;

export const auctionVehiclesAdapter = createEntityAdapter<Vehicle>();

export const offerLogsAdapter = createEntityAdapter<OfferLogData>();

export const adminAuctionVehiclesSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAuctionVehicles: builder.query<EntityWithCount<Vehicle>, AuctionVehiclesQueryArgs>({
      query: ({ auction_id, limit, offset, ...filters }) => ({
        url: `auctions/${auction_id}/vehicles`,
        method: 'GET',
        params: { limit, offset, ...filters }
      }),
      providesTags: ['AuctionVehicles'],
      transformResponse: (response: any) => {
        return {
          rows: auctionVehiclesAdapter.addMany(auctionVehiclesAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        const newVehicles = newItems.rows.ids.map(id => newItems.rows.entities[id]) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          auctionVehiclesAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          auctionVehiclesAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    }),
    getAuctionFilterCounters: builder.query<AuctionStatusFilter, AuctionVehiclesQueryArgs>({
      query: ({ auction_id, ...filters }) => ({
        url: `auctions/${auction_id}/vehicles/counters`,
        method: 'GET',
        params: { ...filters }
      }),
      providesTags: ['AuctionVehicles'],
      forceRefetch: () => true,
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => response.data
    }),
    getAuctionFilterBidders: builder.query<
      Array<{ user_id: number; count: number; dealership_name: string }>,
      AuctionVehiclesQueryArgs
    >({
      query: ({ auction_id, ...filters }) => ({
        url: `auctions/${auction_id}/bidders`,
        method: 'GET',
        params: { ...filters }
      }),
      transformResponse: (response: any) => response.data
    }),
    getAuctionAgents: builder.query<Array<{ agent_id: number; agent_name: string; amount_listed: number }>, number>({
      query: auctionId => ({
        url: `auctions/${auctionId}/vehicles/agents`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data
    }),
    getOffersLog: builder.query<
      EntityWithCount<OfferLogData>,
      {
        auctionId: number;
        sellerId?: number;
        buyerId?: number;
        orderIndex?: number;
        vin?: string;
        page: number;
        perPage: number;
      }
    >({
      query: ({ auctionId, page, perPage, ...filters }) => ({
        url: `admin/auctions/${auctionId}/offers-log`,
        method: 'GET',
        params: { limit: perPage, offset: page, ...filters }
      }),
      transformResponse: (response: any) => {
        return {
          rows: offerLogsAdapter.addMany(offerLogsAdapter.getInitialState(), response.data.data),
          count: Number(response.data.count)
        };
      },
      keepUnusedDataFor: 0,
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { page, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        console.log('in merge?');
        const newLogs = newItems.rows.ids.map(id => newItems.rows.entities[id]) as OfferLogData[];
        currentCache.count = newItems.count;
        console.log('in page123zxc', arg.page);
        if (arg.page === 1) {
          offerLogsAdapter.setAll(currentCache.rows, newLogs);
        } else {
          offerLogsAdapter.addMany(currentCache.rows, newLogs);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    }),
    getOffersLogFilterOptions: builder.query<
      {
        sellers: Array<{ id: number; dealership_name: string }>;
        buyers: Array<{ id: number; dealership_name: string }>;
      },
      { auctionId: number }
    >({
      query: ({ auctionId }) => ({
        url: `admin/auctions/${auctionId}/offers-log/filter-options`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data
    }),
    getRunningVehiclesCount: builder.query<number, number>({
      query: auctionId => ({
        url: `admin/auction/${auctionId}/active-vehicles/count`,
        method: 'GET'
      }),
      providesTags: ['AuctionVehicles'],
      transformResponse: (response: any) => response.data,
      async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved, cacheDataLoaded, getState }) {
        const socket = getSocket();

        const listener = ({ payload, type }: SocketMessage) => {
          if (type === 'WS_CAR_RUN_ENDED') {
            return updateCachedData(draft => {
              return draft - 1;
            });
          }

          if (type === 'WS_CAR_RUN_STARTED') {
            return updateCachedData(draft => {
              return draft + 1;
            });
          }
        };
        try {
          await cacheDataLoaded;
          socket.emit('subscribe', `auctions:${arg}`);
          socket.on('message', listener);
        } catch {}
        await cacheEntryRemoved;
        socket.off('message', listener);
      }
    }),
    getRelistData: builder.query<VehicleRelistData, number>({
      query: vehicleId => ({
        url: `vehicles/${vehicleId}/relist/data`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => response.data
    }),
    relistVehicle: builder.mutation<CreateRelistPayload, any>({
      query: ({ vehicleId, ...payload }) => ({
        url: `vehicles/${vehicleId}/relist`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['RelistHistory', 'AuctionVehicles', 'AuctionsList'],
      onCacheEntryAdded: async ({ id }, { cacheDataLoaded, dispatch }) => {
        try {
          dispatch(resetWithoutType());
          await cacheDataLoaded;
          dispatch(snackShow({ hideTime: 3000, message: 'Vehicle was relisted.' }));
        } catch (error) {
          dispatch(snackShow({ hideTime: 3000, message: 'Error while relisting vehicle.', type: 'error' }));
        }
      }
    }),
    extendVehicleTime: builder.mutation<void, { vehicleId: number }>({
      query: ({ vehicleId }) => ({
        url: `vehicles/${vehicleId}/extend-time`,
        method: 'POST'
      }),
      invalidatesTags: ['RelistHistory'],
      onCacheEntryAdded: async ({ vehicleId }, { cacheDataLoaded, dispatch }) => {
        try {
          dispatch(modalsHide(`extendVehicleTime-${vehicleId}`));
          await cacheDataLoaded;
          dispatch(snackShow({ hideTime: 3000, message: 'Vehicle time was extended.' }));
        } catch (error) {
          dispatch(snackShow({ hideTime: 3000, message: 'Error while extending vehicle time.', type: 'error' }));
        }
      }
    }),
    getRelistHistory: builder.query<any, number>({
      query: vehicleId => ({
        url: `vehicles/${vehicleId}/relist/history`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => response.data,
      providesTags: ['RelistHistory']
    }),
    getReferrers: builder.query<Array<{ id: number; first_name: string; count: number }>, number>({
      query: auctionId => ({
        url: `/auctions/${auctionId}/referrers`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data
    }),
    getVehicleByOrderIndex: builder.query<
      {
        vehicleId: number;
        title: string;
        buyers: Array<{ user_id: number; dealership_name: string; is_bidder: boolean }>;
        highestBid: {
          user_id: number;
          amount: number;
        } | null;
        proxyBid: {
          user_id: number;
          amount: number;
        } | null;
        startingBid: number | null;
      },
      { auctionId: number; orderIndex: number }
    >({
      query: ({ auctionId, orderIndex }) => ({
        url: `/admin/auctions/${auctionId}/order-index/${orderIndex}`,
        method: 'GET'
      }),
      keepUnusedDataFor: 90,
      transformResponse: (response: any) => response.data
    }),
    getMinBuyerOfferAmount: builder.query<MinBuyerOffer, { vehicleId: number; buyerId: number }>({
      query: ({ vehicleId, buyerId }) => ({
        url: `/vehicles/${vehicleId}/offers/${buyerId}/min-amount`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => response.data
    }),
    makeOfferForBuyer: builder.mutation<void, { vehicleId: number; buyerId: number; amount: number }>({
      query: ({ vehicleId, buyerId, amount }) => ({
        url: `vehicles/${vehicleId}/offers/${buyerId}/make`,
        method: 'POST',
        body: {
          amount
        }
      }),
      transformResponse: (response: any) => response.data,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Offer was placed' }));
        } catch (e) {
          dispatch(
            snackShow({
              message: 'Error while making offer',
              type: 'error'
            })
          );
        }
      }
    }),
    toggleOrderLock: builder.mutation<void, number>({
      query: auctionId => ({
        url: `auctions/${auctionId}/toggle-order-lock`,
        method: 'POST'
      }),
      invalidatesTags: ['AuctionOrderLocked', 'AuctionVehicles']
    }),
    isOrderLocked: builder.query<boolean, number>({
      query: auctionId => ({
        url: `auctions/${auctionId}/order-lock`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ['AuctionOrderLocked']
    })
  }),
  overrideExisting: true
});

export const legacyAuctionVehiclesSelector = (state: any, auctionId: number) => {
  const { perPage, filters } = state.auctionVehicles;

  const auctions = adminAuctionVehiclesSlice.endpoints.getAuctionVehicles.select({
    limit: perPage,
    auction_id: String(auctionId),
    ...filters
  })(state)?.data?.rows;

  return auctions;
};

export const {
  useGetAuctionVehiclesQuery,
  useLazyGetAuctionVehiclesQuery,
  useGetAuctionFilterCountersQuery,
  useLazyGetAuctionFilterCountersQuery,
  useLazyGetAuctionFilterBiddersQuery,
  useGetAuctionFilterBiddersQuery,
  useLazyGetAuctionAgentsQuery,
  useGetOffersLogQuery,
  useGetOffersLogFilterOptionsQuery,
  useGetRunningVehiclesCountQuery,
  useGetRelistDataQuery,
  useRelistVehicleMutation,
  useGetRelistHistoryQuery,
  useExtendVehicleTimeMutation,
  useGetReferrersQuery,
  useGetVehicleByOrderIndexQuery,
  useLazyGetVehicleByOrderIndexQuery,
  useGetMinBuyerOfferAmountQuery,
  useMakeOfferForBuyerMutation,
  useToggleOrderLockMutation,
  useIsOrderLockedQuery
} = adminAuctionVehiclesSlice;
